<template>
  <div class="add-form">
    <el-form :model="form" label-position="top" size="small" :rules="rules" ref="form">
      <el-form-item :label="$t('location')" required>
        <checkout-list :data.sync="locationList" />
      </el-form-item>

      <el-form-item :label="$t('Namespace')" prop="metadata.namespace">
        <type-list type="namespace" :data.sync="form.metadata.namespace" :locationList="locationList" />
      </el-form-item>

      <el-form-item label="类型">
        <el-select v-model="fileType" style="width: 100%;" @change="fileTypeChange" disabled>
          <el-option value="basic" label="基本类型">
            <span class="pull-left">基本类型</span>
            <span class="pull-right desc">在提交时不会经过base64转换，配置项的值允许手动修改 </span>
          </el-option>

          <el-option value="binary" label="二进制类型">
            <span class="pull-left">二进制类型</span>
            <span class="pull-right desc">
              在提交时经过base64转换，配置项的值不允许手动修改
            </span>
          </el-option>
        </el-select>

        <span class="help">
          类型会影响提交时配置项的编码方式与配置项是否可编辑
        </span>

        <span class="help">
          基本类型适用于文本等普通配置文件，二进制类型适用于证书等二进制文件
        </span>
      </el-form-item>

      <el-form-item :label="$t('configMapName')" prop="metadata.name">
        <el-input v-model="form.metadata.name"></el-input>
        <span class="help">{{ $t("tips.nameCommonPattern") }}</span>
      </el-form-item>

      <el-form-item :label="$t('tag')">
        <add-labels :labels.sync="form.metadata.labels" />
        <span class="help">{{ $t("tips.addlabels") }}</span>
      </el-form-item>

      <item-list :data="form.data" :is-binary="fileType == 'basic' ? false : true" ref="configItem" />

      <el-form-item>
        <el-button type="primary" @click="submit" size="small" :loading="loading" plain>
          {{ $t("handle.submit") }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ItemList from "./ItemList";
import CheckoutList from "@/components/CheckoutList";
import { configMapSubmit } from "api/configmap";
import { secretSubmit } from "api/secret";

export default {
  components: {
    ItemList,
    CheckoutList
  },

  data() {
    return {
      locationList: [],
      loading: false,
      form: {
        data: {},
        kind: "ConfigMap",
        metadata: {
          labels: {},
          name: "",
          namespace: "",
          annotations: {}
        }
      },

      rules: {
        "metadata.name": [
          { required: true, message: this.$t("rules.require"), trigger: "blur" },
          {
            pattern: /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/,
            message: this.$t("rules.pattern"),
            trigger: "blur"
          }
        ],
        "metadata.namespace": [{ required: true, message: this.$t("rules.require"), trigger: "change" }]
      },

      fileType: "basic"
    };
  },

  computed: {
    vendor() {
      return this.locationList[0];
    },

    region() {
      return this.locationList[1];
    },

    cluster() {
      return this.locationList[2];
    },

    type() {
      return this.$route.path.includes("ConfigMap") ? "ConfigMap" : "Secret";
    }
  },

  methods: {
    fileTypeChange() {
      this.$refs["configItem"].list = [];
    },

    submit() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;
          let key = "";

          if (this.type == "Secret") {
            this.$refs["configItem"].list.forEach(item => {
              this.form.stringData[item.key] = item.value;
            });
            key = "secrets.kubestar.io/type";
          }

          if (this.type == "ConfigMap") {
            this.$refs["configItem"].list.forEach(item => {
              this.form.data[item.key] = item.value;
            });
            key = "configmap.kubestar.io/type";
          }

          if (this.fileType == "binary") this.$set(this.form.metadata.annotations, key, "binary");

          this.$set(this.form.metadata.labels, "app", this.form.metadata.name);

          let action = this.type == "Secret" ? secretSubmit : configMapSubmit;

          action(this.form, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.$router.push({ path: `/list/${this.type}` });
            }
          });
        }
      });
    }
  },

  mounted() {
    if (this.type == "Secret") {
      this.$set(this.form, "stringData", {});
      this.$set(this.form, "kind", "Secret");
    }

    if (this.type == "ConfigMap") {
      this.$set(this.form, "data", {});
      this.$set(this.form, "kind", "ConfigMap");
    }
  }
};
</script>
